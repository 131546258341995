import {
    SystemState,
    SystemActionTypes,
  } from "./types";
  
  import produce from 'immer';
  
  import { SIMULATE_BULLETIN_VALIDE, SIMULATE_BULLETIN_INVALIDE } from "./actions";
  
  const initialState: SystemState = {
    CurrentStep: 'verify-token'
  }
  
  // const initialState: SystemState = {
  //   CurrentStep: 'voting'
  // }

  export const systemReducer = (state:SystemState = initialState, action: SystemActionTypes): SystemState =>
    produce(state, draft => {
      switch (action.type) {
        case SIMULATE_BULLETIN_VALIDE:
          draft.CurrentStep = 'voting';
          break;
        case SIMULATE_BULLETIN_INVALIDE:
          draft.CurrentStep = 'denied';
          draft.Message = action.payload;
          break;
        default:
          return draft;
    }
  });