import React from 'react'
import { connect } from 'react-redux'
import { AppState } from '../../store'

import { Header, CardGroup } from 'semantic-ui-react'

import { CandidatCard } from './CandidatCard/CandidatCard'

import * as model from '../../models/voteModel'


interface Props {
    Candidats: model.Candidat[]
}

class _candidats extends React.Component<Props> {

    render() {
        return (
            <>
                <Header size='huge'>Vos candidates</Header>
                <CardGroup>
                    {this.props.Candidats.map(candidat => <CandidatCard key={candidat.Id} {...candidat} />)}
                </CardGroup>
            </>
        )
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        Candidats: state.campagne.Candidats,
    }
};



const Candidats = connect(mapStateToProps)(_candidats);
export {Candidats}

