import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import 'semantic-ui-css/semantic.min.css'

import App from './components/app/App';

import './index.scss'

import {configureStore} from "./store";
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import {history} from './store';

const store = configureStore();

ReactDOM.render(
  // Uncomment pour activer certaines vérifications,  je le désactive ici 
  // car Semantic donne quelques warning qu'ils n'ont pas encore fixé
  // <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <App />
      </ConnectedRouter>
    </Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();