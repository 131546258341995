import React from 'react'
import {
    Menu
  } from 'semantic-ui-react'



export const Footer: React.FC = () => {
 return  (
    <Menu inverted fixed='bottom' size='tiny' borderless  >
            <Menu.Item as='a' href='http://www.neosapiens.com'>Vote en ligne - &copy; Neosapiens 2020</Menu.Item>
            <Menu.Item as='a' href='mailto://support@neosapiens.com' position='right'>Contactez Nous</Menu.Item>
    </Menu>
 )
}
