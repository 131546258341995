import React, { useEffect } from 'react'
import { RouteChildrenProps } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../../../store';
import * as model from '../../../models/voteModel';
import { Header, List } from 'semantic-ui-react';
import { changeActiveScrutin } from '../../../store/campagne/actions';

export const ChoixScrutin:React.FC<RouteChildrenProps> = (props) => {

    const campagne = useSelector<AppState>(state => state.campagne.Campagne) as model.Campagne;
    const dispatch = useDispatch();

    useEffect(() => {
        // Ce controle n'est pertinent que si on a plusieurs scrutin, sinon on redirect
        if (campagne && campagne.Scrutins.length < 2) {
            props.history.replace('/');
        }
    })

    const changerDeScrutin = (scrutinId:number) => {
        dispatch(changeActiveScrutin(scrutinId));
        props.history.push('/');
    }

    return (
        <> {campagne &&
            <>
                <Header size='huge'>Vous êtes invité-e à voter pour plusieurs scrutins</Header>
                <Header size='large'>Vous pouvez choisir un des scrutins suivants</Header>
                <List celled selection animated relaxed size='large' verticalAlign='middle'>
                    {campagne.Scrutins.map(scrutin => (
                        <List.Item key={scrutin.Id} onClick={() => changerDeScrutin(scrutin.Id)}>
                            <List.Icon name={scrutin.Soumis ? 'check square outline' : 'square outline'} size='big' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{scrutin.Titre}</List.Header>
                                <List.Description>{scrutin.Soumis && 'Vous avez déjà voté, merci'}</List.Description>
                            </List.Content>
                            
                        </List.Item>
                    ))}
                </List>
            </>
            }
            {!campagne && <div></div>}

        </>
    )
}
