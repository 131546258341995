import React from 'react'
import marked from 'marked';


const MarkdownArea: React.FC = (props) => {
    return (
        (props.children && <div dangerouslySetInnerHTML={{__html: marked(props.children?.toString(), {breaks:true})}}></div>) || <div></div>
    )
}


export {MarkdownArea}