import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { AppState } from '../../../../store';
import * as model from '../../../../models/voteModel';
import { Header, List, Button, Divider, Icon } from 'semantic-ui-react';
import { RouteChildrenProps, Link } from 'react-router-dom';

export const Revision: React.FC<RouteChildrenProps> = (props) => {

    const campagne = useSelector<AppState>(state => state.campagne.Campagne) as model.Campagne;
    const activeScrutinId = useSelector<AppState>(state => state.campagne.ActiveScrutinId) as number;
    const activeScrutin = campagne?.Scrutins.find(s => s.Id === activeScrutinId);
    

    useEffect(() => {
        if (!activeScrutin || activeScrutin.Questions.some(q => !q.ReponseId)) {
            props.history.push('/Vote');
        }
      });

    return (
        <>
            { activeScrutin && <>
                <Header size='huge'>Revisez vos choix avant de les soumettres</Header>
                <List relaxed divided size='large' verticalAlign='middle'>
                    {activeScrutin.Questions.map(q => 
                        <List.Item key={q.Id}>
                            {q.ReponseId !== -1 && <List.Icon name='checkmark' color='green' verticalAlign='middle' />}
                            {q.ReponseId === -1 && <List.Icon name='flag outline' color='yellow' verticalAlign='middle' />}
                            <List.Content>
                                <List.Header>{q.Texte}</List.Header>
                                <List.Description>{q.ReponseId !== -1 ? q.Choix.find(c => c.Id === q.ReponseId)?.Texte: "j'ai décidé-e de m'abstenir" }</List.Description>
                            </List.Content>
                        </List.Item>)}
                    <Divider />
                    <Button primary icon labelPosition='right' as={Link} to='/ChoixScrutin' >
                                Soumettre mon vote
                                <Icon name='send' />
                            </Button>
                </List>
            </>}
        </>
    )
}