import React from 'react'
import { useSelector } from 'react-redux'
import * as model from '../../../models/voteModel';
import {AppState} from '../../../store';
import { RouteChildrenProps } from 'react-router-dom';
import { Grid, Image, Header } from 'semantic-ui-react';

interface CandidatRouteProps {
    id: string
}

const Candidat:React.FC<RouteChildrenProps<CandidatRouteProps>> = (props) => {
    const candidats = useSelector<AppState>(state => state.campagne.Candidats) as  model.Candidat[];
    const id = parseInt(props.match? props.match.params.id : '-1');
    const candidat = candidats.find(c => c.Id === id);
    return( 
        <>
            {candidat && (
                <>
                    <Grid>
                        <Grid.Row>
                            <Grid.Column width={3}>
                                <Image src={`https://randomuser.me/api/portraits/women/${candidat.Id}.jpg`} />
                                <Header size='large'>{candidat.Prenom} {candidat.Nom}</Header>
                                <div>{candidat.Info}</div>
                            </Grid.Column>
                            <Grid.Column width={8} textAlign='justified'>
                                <p>{candidat.Description}</p>
                                <p>Mauris a dolor in augue rutrum sollicitudin eu eget enim. Suspendisse sed est at est maximus molestie. Morbi scelerisque tortor eget ante facilisis aliquam. Donec eget eros pharetra, placerat augue at, iaculis purus. Morbi ac orci accumsan, egestas diam vitae, pharetra nulla. Morbi venenatis metus eget turpis vestibulum, eu vulputate ante placerat. Pellentesque ac tellus id mi vestibulum cursus ac sit amet ex. Integer imperdiet ultrices elementum.</p>

                                <p>Praesent molestie eros eu tellus fermentum commodo. Vivamus ultricies ut risus at varius. Interdum et malesuada fames ac ante ipsum primis in faucibus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum scelerisque nisi ut consectetur luctus. Nulla nec tortor hendrerit, ultricies erat eu, pellentesque velit. Nam dictum euismod dui, ut lacinia lorem aliquet eget. Etiam finibus mauris purus, porta aliquet orci viverra quis. Curabitur at molestie mi. Sed faucibus augue nec purus consectetur bibendum.</p>

                                <p>Etiam non sapien eget nibh efficitur ultrices eget ut sapien. Nullam scelerisque libero massa, ut faucibus dolor posuere quis. In posuere, sapien id luctus varius, justo massa lobortis ipsum, eget semper dolor urna nec augue. Donec justo sem, lobortis vitae tincidunt ut, fermentum cursus lorem. Maecenas enim justo, aliquet ac orci non, bibendum facilisis quam. Ut porttitor diam id nisl porta malesuada. Vivamus quis molestie arcu, sit amet consectetur lectus. Nullam molestie tortor dui, non tristique massa hendrerit sit amet. Nulla at ex quis felis maximus ornare. Etiam eleifend diam lorem, a tristique erat ornare non. Mauris fringilla lacinia metus, ac vehicula nisi.</p>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </>
            )}
        </>
    )
}
export { Candidat };