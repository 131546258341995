import React from 'react';
import { connect } from 'react-redux';

import {Layout} from '../Layout/Layout';

import { AppState } from '../../store';
import { SystemStep } from '../../store/system/types';

import './App.scss'

type Props =LinkDispatchProps & LinkStateProps;

class App extends React.Component<Props, {}> {
  
  render() { 
    return (
        <Layout />
    );
  }
}

interface LinkStateProps {
  CurrentStep: SystemStep
}

interface LinkDispatchProps {
}

const mapStateToProps = (state: AppState) => ({
  CurrentStep : state.system.CurrentStep
})

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
