import React from 'react'
import { useSelector } from 'react-redux';

import { NavLink, Link } from 'react-router-dom';
import { Menu,  Header, Image, Container } from 'semantic-ui-react'

import { AppState } from '../../../store'
import * as model from '../../../models/voteModel';
import classes from './Banner.module.scss';

export const Banner = () => {
  
  const campagne = useSelector<AppState>(state => state.campagne.Campagne) as model.Campagne;
  const activeScrutinId = useSelector<AppState>(state => state.campagne.ActiveScrutinId) as number;
  const activeScrutin = campagne?.Scrutins.find(s => s.Id === activeScrutinId);
  const nbScrutins = campagne && campagne.Scrutins.length;
  
  return (
    <>
    {campagne &&
      <Menu className={classes.Banner} inverted fixed='top' borderless stackable>
        <Container>
            <Menu.Item className={classes.BannerItem} >
              <Menu.Item fitted as={Link} to="/">
              <Image src='/FIQ_Logo.png'/>
              <div className={classes.BannerTitreContainer}>
                <Header className={classes.BannerTitre} size='small'>{activeScrutin && activeScrutin.Titre}</Header>
                <Header className={classes.BannerTitre}  size='large'>{campagne.Titre}</Header>
              </div>
              </Menu.Item>
            </Menu.Item>
            <Menu.Item className={classes.BannerItem} as={NavLink} to="/Vote" exact>Votez maintenant</Menu.Item>
            {activeScrutin?.AvecCandidats && <Menu.Item className={classes.BannerItem} as={NavLink} to="/Candidats">Les Candidates</Menu.Item>}
            {nbScrutins>1 && <Menu.Item className={classes.BannerItem} as={NavLink} to="/ChoixScrutin">Autres scrutins</Menu.Item>}
        </Container>
      </Menu>}
      {!campagne && <div></div>}
    </>

  )
}