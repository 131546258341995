import React from 'react'
import {
    Message, Icon,
  } from 'semantic-ui-react'


interface Props {
 titre?: string,
 message: string
}
export const LoadingMessageBox: React.FC<Props> = ({titre, message}) => {
 return (
    <Message floating icon>
        <Icon name='circle notched' loading />
        <Message.Content>
        <Message.Header>{titre || 'Un instant svp...'}</Message.Header>
            {message}
        </Message.Content>
    </Message>
 );
}