import React from 'react';
import {connect} from 'react-redux';

import Scrutin from './scrutin/Scrutin';
import { LoadingMessageBox } from '../loadingMessageBox/LoadingMessageBox';

import { AppState } from "../../store";
import * as model from '../../models/voteModel'
import { Button, Icon, Divider } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

interface CampagneProps {
    ActiveScrutinId?: number,
    Campagne?: model.Campagne,
    pathname: string
}

type Props = CampagneProps & LinkDispatchProps;

class _campagne extends React.Component<Props, {}> {
    
    render() {

        const {Campagne, ActiveScrutinId} = this.props;
        const ActiveScrutin = Campagne?.Scrutins.find(s => s.Id === ActiveScrutinId);
        const nbRestant = ActiveScrutin && ActiveScrutin.Questions.filter(q => !q.ReponseId).length;
        const canGoForward = nbRestant === 0;
       
        return ( 
            <>
                {
                    Campagne && ActiveScrutin && (
                        <>
                            <Scrutin {...ActiveScrutin} />
                            <Divider />
                            <Button primary icon labelPosition='right' disabled={!canGoForward} as={Link} to='/Revision' >
                                {canGoForward && <>Vérifier mes choix</>}
                                {!canGoForward && <>{nbRestant} réponses manquante{nbRestant && nbRestant > 1 && <>s</>}</>}
                                <Icon name='list alternate outline' />
                            </Button>
                        </>
                    )
                } 
                { 
                    !Campagne && (
                        <LoadingMessageBox  message='Chargement de la campagne de vote en cours...' />
                    )
                }
            </>
        );
    }
}

interface LinkDispatchProps {
    selectAnswer: (questionId:number, answerId?:number) => void,
    changeActiveGroup : (requestedGroupId:number) => void
}


const mapStateToProps = (state: AppState) => {
    return {
        Campagne: state.campagne.Campagne,
        ActiveScrutinId: state.campagne.ActiveScrutinId,
        pathname: state.router.location.search,
    }
};



const Campagne = connect(mapStateToProps)(_campagne);

export {Campagne} ;