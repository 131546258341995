import {
  CampagneState,
  CampagneActionTypes,
} from "./types";
import produce from 'immer';

import { LOAD_CAMPAGNE, SELECT_ANSWER, CHANGE_ACTIVE_SCRUTIN } from "./actions";

import {campagne, candidats} from '../../testdata';


export const campagneReducer = (state:CampagneState = {Candidats:[]}, action: CampagneActionTypes): CampagneState =>
   produce(state, draft => {
    switch (action.type) {
      case LOAD_CAMPAGNE:
        draft.Campagne = campagne;
        draft.ActiveScrutinId = draft.Campagne?.Scrutins[0]?.Id
        draft.Candidats = candidats
        break;

      case CHANGE_ACTIVE_SCRUTIN:
        draft.ActiveScrutinId = draft.Campagne?.Scrutins.find(s => s.Id === action.payload.requestedScrutinId)?.Id;
        break;

        case SELECT_ANSWER:
          if (draft.Campagne) {
            draft.Campagne.Scrutins.forEach(grp => { 
              grp.Questions.forEach(q => {
                if (q.Id === action.payload.questionId) {
                  q.ReponseId = action.payload.answerId;
                }
              })
            });
          }
          break;

        default:
          return draft;
  }
});