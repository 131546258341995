import React from 'react'
import {
    Header,
    List,
  } from 'semantic-ui-react'

import './question.scss';

import * as model from '../../../../models/voteModel'
import { useDispatch } from 'react-redux';
import { selectAnswer } from '../../../../store/campagne/actions';
 
 
const Question: React.FC<model.Question> = ({Id, Choix, Texte, ReponseId}) => {

    const dispatch = useDispatch();

    const onSelectAnswer = (questionId:number, answerId:number) => {
        dispatch(selectAnswer(questionId, answerId));
    }
 
    return  (<List.Item>
        <Header size="large">{Texte}</Header>
        <List.Content>
            <List selection animated relaxed size='large' verticalAlign='middle'>
                {Choix.map(choix => <ChoixReponse key={choix.Id} {...choix} Choisi={choix.Id === ReponseId} onAnswerClicked={(answerId:number) => onSelectAnswer(Id, answerId)} />)}
                <ChoixReponse key='-1' Id={-1} Choisi={-1 === ReponseId} Texte="Je veux m'abstenir" onAnswerClicked={(answerId:number) => onSelectAnswer(Id, answerId)} />
            </List>
        </List.Content>
    </List.Item>);
}



interface ReponseProps {
    onAnswerClicked: (answerId:number) => void,
    Choisi: boolean
}
const ChoixReponse = ({Id, Texte, onAnswerClicked, Choisi, Info}:ReponseProps & model.Choix) => (
    <List.Item onClick={() => onAnswerClicked(Id)}>
         <List.Icon name={Choisi ? 'check square outline' : 'square outline'} size='big' verticalAlign='middle' />
         <List.Content>
            <List.Header>{Texte}</List.Header>
            <List.Description>{Info}</List.Description>
        </List.Content>
    </List.Item>
)

export {Question};