import React from 'react'
import * as model from '../../../models/voteModel';
import { Card, Image } from 'semantic-ui-react';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';


const _candidat:React.FC<model.Candidat & RouteComponentProps> = (props) => {
    return( 
        <Card as={Link} to={'Candidat/' + props.Id}>
        <Card.Content>
            <Image
            floated='left'
            size='mini'
            src={`https://randomuser.me/api/portraits/thumb/women/${props.Id}.jpg`}
            />
            <Card.Header>{props.Prenom} {props.Nom}</Card.Header>
            <Card.Meta>{props.Info}</Card.Meta>
            <Card.Description>
                {props.Description}
            </Card.Description>
        </Card.Content>
        
    </Card>
    )
}

const CandidatCard = withRouter(_candidat);

export { CandidatCard };