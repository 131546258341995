export const SIMULATE_BULLETIN_VALIDE = 'SIMULATE_BULLETIN_VALIDE';
export const SIMULATE_BULLETIN_INVALIDE = 'SIMULATE_BULLETIN_INVALIDE';


export interface SimulateBulletinValideAction {
  type: typeof SIMULATE_BULLETIN_VALIDE
}

export interface SimulateBulletinInvalideAction {
  type: typeof SIMULATE_BULLETIN_INVALIDE,
  payload: string
}

export function simulateBulletinValide() {
  return {
    type: SIMULATE_BULLETIN_VALIDE
  };
}

export function simulateBulletinInvalide(raison: string) {
  return {
    type: SIMULATE_BULLETIN_INVALIDE,
    payload: raison
  };
}




  