import { createStore, combineReducers, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import { campagneReducer } from "./campagne/reducers";
import { CampagneActionTypes } from "./campagne/types";
import { SystemActionTypes } from "./system/types";
import { systemReducer } from "./system/reducers";

import { createBrowserHistory } from 'history';
import { connectRouter, routerMiddleware } from 'connected-react-router'

export const history = createBrowserHistory();
export const rootReducer = combineReducers({
  campagne: campagneReducer,
  system: systemReducer,
  router: connectRouter(history),
});


export type AppState = ReturnType<typeof rootReducer>;
export type AppActions = CampagneActionTypes | SystemActionTypes;

export function configureStore() {
  const middlewares = [thunkMiddleware, routerMiddleware(history)];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
 