import React from 'react'

import { Header, List } from 'semantic-ui-react'
import {Question} from './question/Question'

import * as model from '../../../models/voteModel';
import './scrutin.scss'

const component : React.FC<model.Scrutin> = ({Titre, Explication, Questions}) => {

    return  (
        <>
            <Header size='huge'>{Titre}</Header>
            <p className="texte-explicatif">{Explication}</p>
            <List divided relaxed>
                {Questions.map(data => <Question key={data.Id} {...data} />)}
            </List>
        </>
    );
}

export default component;