export const LOAD_CAMPAGNE = 'LOAD_CAMPAGNE';
export const SELECT_ANSWER = 'SELECT_ANSWER';
export const CHANGE_ACTIVE_SCRUTIN = 'CHANGE_ACTIVE_SCRUTIN';


export interface LoadCampagneAction {
  type: typeof LOAD_CAMPAGNE,
  payload: number
}

export interface SelectAnswerAction {
  type: typeof SELECT_ANSWER,
  payload: {
    questionId: number,
    answerId?: number,
  }
}

export interface ChangeActiveScrutinAction {
  type: typeof CHANGE_ACTIVE_SCRUTIN,
  payload: {
    requestedScrutinId: number
  }
}

export function selectAnswer(questionId:number, answerId?:number) {
  return {
    type:SELECT_ANSWER,
    payload:{
      questionId,
      answerId
    }
  }
}

export function changeActiveScrutin(requestedScrutinId: number) {
  return {
    type:CHANGE_ACTIVE_SCRUTIN,
    payload:{
      requestedScrutinId
    }
  }
}

export function loadCampagne(campagneId: number) {
  return {
    type: LOAD_CAMPAGNE,
    payload: campagneId
  };
}


