import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom';

import { Container } from 'semantic-ui-react';

import { Campagne } from '../campagne/Campagne';
import { Candidats } from '../Candidats/Candidats';
import { Banner } from '../campagne/banner/Banner';
import { Candidat } from '../Candidats/Candidat/Candidat';
import { Footer } from './footer/Footer'
import { Accueil } from '../campagne/Accueil/Accueil';

import classes from './Layout.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { loadCampagne } from '../../store/campagne/actions';
import { AppState } from '../../store';
import { ChoixScrutin } from '../campagne/ChoixScrutin/ChoixScrutin';

 import * as model from '../../models/voteModel';
import { Revision } from '../campagne/scrutin/Revision/Revision';

export const Layout: React.FC = () => {
    const dispatch = useDispatch();
    const campagne = useSelector<AppState>(state => state.campagne.Campagne) as model.Campagne;
    const activeCampagenId = useSelector<AppState>(state => state.campagne.ActiveScrutinId) as number;
    const activeScrutin = campagne?.Scrutins.find(s => s.Id === activeCampagenId);

    useEffect(() => {
        if (!campagne) {
          dispatch(loadCampagne(0)); //TODO: Replace
        }
      });
    return (
    <>
        <Banner />
        <Container className={classes.MainContainer}>
            {
                campagne && activeScrutin && 
                <Switch>
                    {activeScrutin?.AvecCandidats && <Route path='/Candidat/:id' component={Candidat} />}
                    {activeScrutin?.AvecCandidats && <Route path='/Candidats' component={Candidats} />}
                    <Route path='/ChoixScrutin' component={ChoixScrutin} />
                    <Route path='/Vote' component={Campagne} />
                    <Route path='/Revision' component={Revision} />
                    <Route path='/' component={Accueil} />
                </Switch>
            }
            
        </Container>
        <Footer />
    </>
)};