import {Campagne, Candidat} from './models/voteModel'

const campagne:Campagne =  {
    Id: 1,
    Titre: 'Élections 2020',
    Scrutins: [
        {
            Id: 1,
            Titre: "Élection du comité exécutif",
            Explication: 'Les candidates et candidats en lice sont identifiés ci-dessous, pour chacun des postes en élection, par ordre alphabétique',
            Description: "An h1 header\r\n============\r\n\r\nParagraphs are separated by a blank line.\r\n\r\n2nd paragraph. *Italic*, **bold**, and `monospace`. Itemized lists\r\nlook like:\r\n\r\n  * this one\r\n  * that one\r\n  * the other one\r\n\r\nNote that --- not considering the asterisk --- the actual text\r\ncontent starts at 4-columns in.\r\n\r\n> Block quotes are\r\n> written like so.\r\n>\r\n> They can span multiple paragraphs,\r\n> if you like.\r\n\r\nUse 3 dashes for an em-dash. Use 2 dashes for ranges (ex., \"it's all\r\nin chapters 12--14\"). Three dots ... will be converted to an ellipsis.\r\nUnicode is supported.",
            Soumis: false,
            VideoYoutubeId:'wlcQc4vHsYU',
            AvecCandidats: true,
            Questions: [
                {
                    Id: 1,
                    Texte: 'Présidence',
                    NbVotantAttendu: 50,
                    Choix:[{
                        Id: 1,
                        Texte: 'Julie St-Pierre',
                        Info: 'Recommandation du Syndicat'
                    },
                    {
                        Id: 2,
                        Texte: 'Pascale Lavoie',
                    },
                    {
                        Id: 3,
                        Texte: 'Jennifer Carpentier',
                    },
                    {
                        Id: 4,
                        Texte: 'Roger Lavoie',
                    },
                    {
                        Id: 5,
                        Texte: 'Hervey Tremblay',
                    }],
                },
                {
                    Id: 2,
                    Texte: '1e Vice-Présidence',
                    NbVotantAttendu: 50,
                    Choix:[
                        {
                            Id: 13,

                            Texte: 'Julie St-Pierre',
                        },
                        {
                            Id: 23,

                            Texte: 'Pascale Lavoie',
                        },
                        {
                            Id: 33,

                            Texte: 'Jennifer Carpentier',
                        },
                        {
                            Id: 43,

                            Texte: 'Roger Lavoie',
                        },
                        {
                            Id: 53,

                            Texte: 'Hervey Tremblay',
                        }
                    ],
                },
                {
                    Id: 3,
                    Texte: '2e Vice-Présidence',
                    NbVotantAttendu: 50,
                    Choix:[{
                        Id: 12,
                        Texte: 'Julie St-Pierre',
                    },
                    {
                        Id: 22,
                        Texte: 'Pascale Lavoie',
                    },
                    {
                        Id: 32,
                        Texte: 'Jennifer Carpentier',
                    },
                    {
                        Id: 42,
                        Texte: 'Roger Lavoie',
                    },
                    {
                        Id: 52,
                        Texte: 'Hervey Tremblay',
                    }],
                },
                {
                    Id: 4,
                    Texte: '3e Vice-Présidence',
                    NbVotantAttendu: 50,
                    Choix:[{
                        Id: 11,
                        Texte: 'Julie St-Pierre',
                    },
                    {
                        Id: 21,
                        Texte: 'Pascale Lavoie',
                    },
                    {
                        Id: 31,
                        Texte: 'Jennifer Carpentier',
                    },
                    {
                        Id: 41,
                        Texte: 'Roger Lavoie',
                    },
                    {
                        Id: 51,
                        Texte: 'Hervey Tremblay',
                    }],
                }
            ]
        },
        {
            Id: 2,
            Titre: 'Offres patronales',
            Description: " Ut iaculis, libero eget ornare euismod, ipsum elit aliquam ligula, eget fringilla turpis elit vitae lacus. Nam suscipit scelerisque erat, quis sollicitudin dolor mollis sed. Vivamus dignissim dapibus mauris. Proin convallis lectus sed risus commodo, efficitur feugiat ex interdum. Nam viverra arcu eget felis ullamcorper, at lacinia ligula convallis. Nunc condimentum arcu augue, at feugiat quam interdum ut. Morbi maximus nisi leo, sit amet condimentum elit molestie eu. Vivamus rhoncus, ligula eget cursus facilisis, urna nunc mattis sapien, sit amet ornare nisi ligula elementum magna. Sed non dictum ligula.\nMorbi et augue eu leo luctus dictum eget et metus. Vivamus non iaculis nunc, sit amet finibus mi. Integer quam risus, sodales vel eros a, pharetra pretium sapien. Mauris molestie molestie diam at aliquam. Praesent a velit nec urna venenatis facilisis vel nec arcu. Nullam sagittis, nisl id fringilla tempus, odio orci rhoncus arcu, et tincidunt est ex id dui. Quisque consequat tortor id semper fermentum. Sed pulvinar lobortis ligula, a blandit lorem porta ut. Nam ullamcorper varius nisl vitae convallis. In hac habitasse platea dictumst. Etiam nec hendrerit tortor. Proin condimentum maximus ipsum vitae convallis. Pellentesque vulputate, odio dapibus semper egestas, quam justo rhoncus quam, et malesuada nisl lacus eget elit. Praesent cursus lectus ipsum, in mattis diam rhoncus sit amet. ",
            Soumis: false,
            VideoYoutubeId: 'alHeD_ZKQJ0',
            AvecCandidats:false,
            Questions:[
                {
                    Id:11,
                    Texte: 'Acceptez vous la dernière offre patronale',
                    Choix: [{
                        Id:111,
                        Texte:"J'accepte l'offre tel que proposée",
                        Info: 'Recommandation du Syndicat'
                        },
                        {
                            Id:112,
                            Texte:"Je refuse et demande la reprise des négociations"
                        },
                    ],

                    NbVotantAttendu:50
                }
            ]
        }
    ]
};

const candidats:Candidat[] = [
    {Id:4,
    Nom:'St-Pierre',
    Prenom: 'Julie',
    Description:'Groupe Roy Santé Inc. ( Syndicat régional des professionnelles en soins du Québec (FIQ) )'},
    {Id:2,
    Nom:'Lavoie',
    Prenom: 'Pascale',
    Info: 'Choix du CA',
    Description:'CHUM (Syndicat des Professionnelles et Professionnels en soins de santé du Centre Hospitalier de l’Université de Montréal (FIQ))'},
    {Id:3,
    Nom:'Carpentier',
    Prenom: 'Jennifer',
    Description:'CHUM ( Syndicat des Professionnelles et Professionnels en soins de santé du Centre Hospitalier de l’Université de Montréal (FIQ) )'},
    {Id:9,
    Nom:'Lavoie',
    Prenom: 'Nathalie',
    Description: 'CIUSSS de l’Estrie – Centre Hosp. Univ. de Sherbrooke ( FIQ – Syndicat des professionnelles en soins des Cantons-de-l’Est )'},
    {Id:5,
    Nom:'Tremblay',
    Prenom: 'Audrey',
    Description: 'Conseil Cri de la santé et serv. soc. de la Baie-James ( Syndicat nordique des infirmières et infirmiers de la Baie James (FIQ) )'},
];

export {campagne, candidats};