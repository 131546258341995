import React from 'react'

import {MarkdownArea} from '../../MardownArea/MarkdownArea';

import { Header, Embed, Button, Divider } from 'semantic-ui-react'
import { useSelector } from 'react-redux';
import { AppState } from '../../../store';

import * as model from '../../../models/voteModel';
import { Link } from 'react-router-dom';

export const Accueil = () => {
    const campagne = useSelector<AppState>(state => state.campagne.Campagne) as model.Campagne;
    const activeScrutinId = useSelector<AppState>(state => state.campagne.ActiveScrutinId) as number;
    const activeScrutin = campagne && activeScrutinId && campagne.Scrutins.find(s => s.Id === activeScrutinId);
    
    return (
            <>
                {campagne && activeScrutin && 
                <>
                    <Header size='huge'>{campagne.Titre} - {activeScrutin.Titre}</Header>
                    <Button primary as={Link} to='/Vote'>Je vote maintenant!</Button>
                    <Divider />
                    {activeScrutin.VideoYoutubeId && <Embed
                            id={activeScrutin.VideoYoutubeId}
                            placeholder={`https://img.youtube.com/vi/${activeScrutin.VideoYoutubeId}/0.jpg`}
                            source='youtube'
                            brandedUI
                        />}
                    <MarkdownArea>{activeScrutin.Description}</MarkdownArea>
                </>
                }
                {!campagne && <div></div>}  
            </>
    )
}
